///<reference path="typings.d.ts"/>
import * as fastWebsiteData from './data/desktop-vs-mobile.json';
import { menu } from "./components/Menu";
import { scroller } from "./components/Scroll";
import { helpers } from './components/helpers';
import { timer } from './components/SiteLoadControll';


window.addEventListener("load", function(){
    menu.init();
    //scroller.init();
    $("#spinner").removeClass("visible").delay( 300 ).addClass("hidden");
    function animateWindow(hash){
        let target = $(hash);
        target = target.length ? target : $('[name=' + hash.slice(1) +']');
        if (target.length) {
            $('html, body').animate({
            scrollTop: target.offset().top
        }, 1000);
        menu.deactivate();
            return false;
        }
    }
    try{
        animateWindow(window.location.hash);
    }catch(err){
        console.log("err", err);
    }
    try{
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname ) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                    if (target.length) {
                        $('body').animate({
                            scrollTop: target.offset().top
                        }, 1000);
                        menu.deactivate();
                        return false;
                }
            }
        });
    }catch(err){ console.log("err", err);}
    try{
        document.getElementById("graph-btn").addEventListener("click", ()=>{
            document.getElementById("top50").classList.toggle("show-all");
            document.getElementById("graph-btn").classList.add("hidden");
        }, true);
    }catch(err) {console.log(err);}
});
$(document).ready(function(){
    if(window.location.href.indexOf("speed-optimisation-analysis")){
        let results:any = fastWebsiteData;
        results.data.forEach(element => {
            $("#top50-table").append(`
                <tr>
                    <td align="center" data-label="Rank">${element.rank}</td>
                    <td align="right" data-label="Website">${element.website}</td>
                    <td class="hide-mobile">
                        <div class="bars" style="width: ${element.scores.desktop}%;height:15px;background:#f4f2b4;margin-bottom:1px;" data-score="${element.scores.desktop}"></div>
                        <div class="bars" style="width: ${element.scores.mobile}%;height:15px;background:#EEE158" data-score="${element.scores.desktop}"></div>
                    </td>
                    <td data-label="Page Size">
                        ${element.pageSize}
                    </td>
                    <td data-label="Loading Time">
                        ${element.loadingTime}
                    </td>
                    <td style="color:#f4f2b4;font-weight:bold;" data-label="Score">
                        ${element.score}
                    </td>
                </tr>

            `);
        });    
    }
})