module.exports={
    "data":[{
        "rank": 1,
        "website": "TM Lewin",
        "pageSize": 2.8,
        "scores": {
            "desktop": 97,
            "mobile": 96
        },
        "loadingTime": 0.9,
        "score": 9.8
    },
    {
        "rank": 2,
        "website": "Jack Wills",
        "pageSize": 2.9,
        "scores": {
            "desktop": 96,
            "mobile": 95
        },
        "loadingTime": 1.7,
        "score": 9.6
    },
    {
        "rank": 3,
        "website": "Triton Cycles",
        "pageSize": 3.5,
        "scores": {
            "desktop": 98,
            "mobile": 98
        },
        "loadingTime": 0.7,
        "score": 9.5
    },
    {
        "rank": 4,
        "website": "Find Me a Gift",
        "pageSize": 0.9,
        "scores": {
            "desktop": 95,
            "mobile": 94
        },
        "loadingTime": 1.7,
        "score": 9.4
    },
    {
        "rank": 5,
        "website": "American Golf",
        "pageSize": 2.7,
        "scores": {
            "desktop": 97,
            "mobile": 95
        },
        "loadingTime": 1.2,
        "score": 9.4
    },
    {
        "rank": 6,
        "website": "ShopStyle",
        "pageSize": 2.6,
        "scores": {
            "desktop": 87,
            "mobile": 95
        },
        "loadingTime": 1.3,
        "score": 9.1
    },
    {
        "rank": 7,
        "website": "Co-op",
        "pageSize": 0.7,
        "scores": {
            "desktop": 89,
            "mobile": 88
        },
        "loadingTime": 1.6,
        "score": 9.1
    },
    {
        "rank": 8,
        "website": "Thorntons",
        "pageSize": 5.2,
        "scores": {
            "desktop": 93,
            "mobile": 90
        },
        "loadingTime": 0.8,
        "score": 9.0
    },
    {
        "rank": 9,
        "website": "Puma",
        "pageSize": 2.7,
        "scores": {
            "desktop": 82,
            "mobile": 94
        },
        "loadingTime": 0.5,
        "score": 9.0
    },
    {
        "rank": 10,
        "website": "Mulberry",
        "pageSize": 1.1,
        "scores": {
            "desktop": 88,
            "mobile": 86
        },
        "loadingTime": 0.8,
        "score": 9.0
    },
    {
        "rank": 11,
        "website": "Console Deals",
        "pageSize": 1.4,
        "scores": {
            "desktop": 77,
            "mobile": 98
        },
        "loadingTime": 1.4,
        "score": 9.0
    },
    {
        "rank": 12,
        "website": "Bedroom World",
        "pageSize": 0.9,
        "scores": {
            "desktop": 90,
            "mobile": 86
        },
        "loadingTime": 4.2,
        "score": 9.0
    },
    {
        "rank": 13,
        "website": "Gear4music",
        "pageSize": 1.8,
        "scores": {
            "desktop": 88,
            "mobile": 86
        },
        "loadingTime": 0.8,
        "score": 8.9
    },
    {
        "rank": 14,
        "website": "SofasWorld",
        "pageSize": 0.8,
        "scores": {
            "desktop": 89,
            "mobile": 86
        },
        "loadingTime": 4.3,
        "score": 8.9
    },
    {
        "rank": 15,
        "website": "Gymshark",
        "pageSize": 6.5,
        "scores": {
            "desktop": 91,
            "mobile": 88
        },
        "loadingTime": 0.5,
        "score": 8.9
    },
    {
        "rank": 16,
        "website": "Etsy",
        "pageSize": 1.5,
        "scores": {
            "desktop": 94,
            "mobile": 79
        },
        "loadingTime": 1.2,
        "score": 8.9
    },
    {
        "rank": 17,
        "website": "Deichmann",
        "pageSize": 0.1,
        "scores": {
            "desktop": 87,
            "mobile": 83
        },
        "loadingTime": 6.2,
        "score": 8.9
    },
    {
        "rank": 18,
        "website": "Forbidden Planet",
        "pageSize": 2.0,
        "scores": {
            "desktop": 89,
            "mobile": 85
        },
        "loadingTime": 1.4,
        "score": 8.9
    },
    {
        "rank": 19,
        "website": "Boohoo",
        "pageSize": 3.5,
        "scores": {
            "desktop": 90,
            "mobile": 83
        },
        "loadingTime": 0.8,
        "score": 8.8
    },
    {
        "rank": 20,
        "website": "Create and Craft TV",
        "pageSize": 1.6,
        "scores": {
            "desktop": 81,
            "mobile": 89
        },
        "loadingTime": 0.8,
        "score": 8.8
    },
    {
        "rank": 21,
        "website": "Create and Craft TV",
        "pageSize": 1.6,
        "scores": {
            "desktop": 81,
            "mobile": 89
        },
        "loadingTime": 1,
        "score": 8.8
    },
    {
        "rank": 22,
        "website": "BuldumBuldum.com",
        "pageSize": 1.9,
        "scores": {
            "desktop": 91,
            "mobile": 78
        },
        "loadingTime": 0.7,
        "score": 8.8
    },
    {
        "rank": 23,
        "website": "GAK",
        "pageSize": 7.1,
        "scores": {
            "desktop": 88,
            "mobile": 87
        },
        "loadingTime": 0.5,
        "score": 8.8
    },
    {
        "rank": 24,
        "website": "Spead Shirt",
        "pageSize": 1.2,
        "scores": {
            "desktop": 87,
            "mobile": 80
        },
        "loadingTime": 0.5,
        "score": 8.8
    },
    {
        "rank": 25,
        "website": "Boux Avenue",
        "pageSize": 4.7,
        "scores": {
            "desktop": 91,
            "mobile": 85
        },
        "loadingTime": 1.5,
        "score": 8.8
    },
    {
        "rank": 26,
        "website": "Up-4ever",
        "pageSize": 4.6,
        "scores": {
            "desktop": 91,
            "mobile": 68
        },
        "loadingTime": 0.5,
        "score": 8.7
    },
    {
        "rank": 27,
        "website": "Zazzle",
        "pageSize": 2.4,
        "scores": {
            "desktop": 85,
            "mobile": 85
        },
        "loadingTime": 1.8,
        "score": 8.7
    },
    {
        "rank": 28,
        "website": "Thread",
        "pageSize": 1.8,
        "scores": {
            "desktop": 91,
            "mobile": 75
        },
        "loadingTime": 0.8,
        "score": 8.7
    },
    {
        "rank": 29,
        "website": "G-star RAW",
        "pageSize": 3.7,
        "scores": {
            "desktop": 90,
            "mobile": 77
        },
        "loadingTime": 0.4,
        "score": 8.7
    },
    {
        "rank": 30,
        "website": "Blinds 2go",
        "pageSize": 1.9,
        "scores": {
            "desktop": 82,
            "mobile": 85
        },
        "loadingTime": 1.6,
        "score": 8.7
    },
    {
        "rank": 31,
        "website": "Groupon",
        "pageSize": 1.8,
        "scores": {
            "desktop": 82,
            "mobile": 72
        },
        "loadingTime": 0.8,
        "score": 8.7
    },
    {
        "rank": 32,
        "website": "The Jewellery Channel",
        "pageSize": 2.4,
        "scores": {
            "desktop": 83,
            "mobile": 83
        },
        "loadingTime": 1.1,
        "score": 8.7
    },
    {
        "rank": 33,
        "website": "Disney Store",
        "pageSize": 2.4,
        "scores": {
            "desktop": 83,
            "mobile": 83
        },
        "loadingTime": 1.1,
        "score": 8.7
    },
    {
        "rank": 34,
        "website": "Warren James",
        "pageSize": 1.4,
        "scores": {
            "desktop": 83,
            "mobile": 79
        },
        "loadingTime": 1.4,
        "score": 8.6
    },
    {
        "rank": 35,
        "website": "Ebay",
        "pageSize": 1.8,
        "scores": {
            "desktop": 72,
            "mobile": 90
        },
        "loadingTime": 1.0,
        "score": 8.6
    },
    {
        "rank": 36,
        "website": "New Balance",
        "pageSize": 4.2,
        "scores": {
            "desktop": 89,
            "mobile": 76
        },
        "loadingTime": 1.0,
        "score": 8.6
    },
    {
        "rank": 37,
        "website": "Home Bargains",
        "pageSize": 0.7,
        "scores": {
            "desktop": 83,
            "mobile": 67
        },
        "loadingTime": 4.7,
        "score": 8.5
    },
    {
        "rank": 38,
        "website": "Mango",
        "pageSize": 6.4,
        "scores": {
            "desktop": 92,
            "mobile": 72
        },
        "loadingTime": 4.7,
        "score": 8.5
    },
    {
        "rank": 39,
        "website": "Bonmarché",
        "pageSize": 2.1,
        "scores": {
            "desktop": 87,
            "mobile": 74
        },
        "loadingTime": 1.0,
        "score": 8.5
    },
    {
        "rank": 40,
        "website": "Oak Furniture Land",
        "pageSize": 1.9,
        "scores": {
            "desktop": 74,
            "mobile": 86
        },
        "loadingTime": 1.9,
        "score": 8.5
    },
    {
        "rank": 41,
        "website": "F.Hinds",
        "pageSize": 1.3,
        "scores": {
            "desktop": 82,
            "mobile": 77
        },
        "loadingTime": 0.9,
        "score": 8.5
    },
    {
        "rank": 42,
        "website": "T J Hughes",
        "pageSize": 2.0,
        "scores": {
            "desktop": 73,
            "mobile": 88
        },
        "loadingTime": 1.5,
        "score": 8.5
    },
    {
        "rank": 43,
        "website": "Oasis",
        "pageSize": 2.0,
        "scores": {
            "desktop": 86,
            "mobile": 78
        },
        "loadingTime": 3.0,
        "score": 8.5
    },
    {
        "rank": 44,
        "website": "Cash Generator",
        "pageSize": 0.1,
        "scores": {
            "desktop": 87,
            "mobile": 70
        },
        "loadingTime": 9.7,
        "score": 8.5
    },
    {
        "rank": 45,
        "website": "RoseGal",
        "pageSize": 1.8,
        "scores": {
            "desktop": 75,
            "mobile": 73
        },
        "loadingTime": 0.8,
        "score": 8.5
    },
    {
        "rank": 46,
        "website": "Adidas",
        "pageSize": 2.7,
        "scores": {
            "desktop": 89,
            "mobile": 79
        },
        "loadingTime": 3.9,
        "score": 8.5
    },
    {
        "rank": 47,
        "website": "Kaleidoscope",
        "pageSize": 1.1,
        "scores": {
            "desktop": 82,
            "mobile": 76
        },
        "loadingTime": 1.3,
        "score": 8.5
    },
    {
        "rank": 48,
        "website": "WorldStores",
        "pageSize": 2.2,
        "scores": {
            "desktop": 90,
            "mobile": 69
        },
        "loadingTime": 0.8,
        "score": 8.5
    },
    {
        "rank": 49,
        "website": "American Eagle Outfitters",
        "pageSize": 0.8,
        "scores": {
            "desktop": 85,
            "mobile": 72
        },
        "loadingTime": 1.2,
        "score": 8.5
    },
    {
        "rank": 50,
        "website": "SSENSE",
        "pageSize": 2.1,
        "scores": {
            "desktop": 94,
            "mobile": null
        },
        "loadingTime": 1.6,
        "score": 8.5
    }
    
]
}
