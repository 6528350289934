interface iMenuUiObj{
    trigger:HTMLElement;
    menu:HTMLElement
}

class Menu{
    private state:boolean = false;

    public init(){
        this.ui.trigger.addEventListener("click", ()=>{
            this.validate();
        });
    }

    private ui:iMenuUiObj = {
        trigger: document.getElementById("menu-trigger"),
        menu: document.getElementById("main-menu")
    }

    private validate(){
       if(this.state){
            this.deactivate();
       }else{
            this.activate();
       }
    }

    private activate(){
        this.ui.menu.classList.remove("hidden");
        document.querySelector("body").classList.add("overflow-hide");
        setTimeout(()=>{
             document.getElementById("nav-icon1").classList.add("open");
            this.ui.menu.classList.add("active");
            this.state = true;
        },100);
       
    }

    public deactivate(){
        document.getElementById("nav-icon1").classList.remove("open");
        document.querySelector("body").classList.remove("overflow-hide");
        this.ui.menu.classList.remove("active");
        setTimeout(()=>{
            this.ui.menu.classList.add("hidden");
        },300);
        this.state = false;
    }
    
}

export var menu = new Menu();